import { compact, isEqual, isNull, sortBy } from 'lodash'

export function areCompactedUniqueArraysEqual(
    array1: any,
    array2: any
): boolean {
    return isEqual(compact(sortBy(array1)), compact(sortBy(array2)))
}

export const genRanHex = (size) =>
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('')

export enum ListSortDirection {
    ASC,
    DESC,
}
