import { ProviderDepartmentOptionItem } from '@docpace/shared-ts-types'
import { ListSortDirection } from '@docpace/shared-ts-utils'
export class ProviderDepartmentOptionSort {
    static readonly PDO_DISABLE_DELAY_CALC = new ProviderDepartmentOptionSort(
        'Disable Delay Calc',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.appointmentsDisableDelayCalc
    )
    
    static readonly PDO_ANCHOR_AUTOPAUSE_TO_SAT = new ProviderDepartmentOptionSort(
        'Anchor Autopause to SAT',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.anchorAutoPauseToSuggestedArrivalTime
    )
    
    static readonly PDO_SAT_MAX_DRIFT = new ProviderDepartmentOptionSort(
        'SAT Max Drift (minutes)',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.suggestedArrivalTimeMaxDriftMinutes
    )
    
    static readonly PDO_AUTOPAUSE_AFTER_MINUTES = new ProviderDepartmentOptionSort(
        'Autopause After (minutes) (pre)',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.autoPauseAfterMinutes
    )
    
    static readonly PDO_FORCE_PAUSE_AFTER_MINUTES = new ProviderDepartmentOptionSort(
        'Force pause After (minutes) (pre)',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.forcePauseAfterMinutes
    )
    
    static readonly PDO_HIDE_ON_MANAGER_SITE = new ProviderDepartmentOptionSort(
        'Hide on Manager Site',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.appointmentsHideOnManager
    )
    
    static readonly PDO_EXCLUDE_FROM_VIEWS_AND_QUEUES = new ProviderDepartmentOptionSort(
        'Exclude from Views and Queues',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.excludeFromViewsAndQueues
    )
    
    static readonly PDO_SHOW_SUGGESTED_ARRIVAL = new ProviderDepartmentOptionSort(
        'Show Suggested Arrival',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.showSuggestedArrivalTimeInVwr
    )
    
    static readonly PDO_SHOW_CHECK_IN_BUTTON_IN_VWR = new ProviderDepartmentOptionSort(
        'Show Check-in Button on Patient Site',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.showCheckInButtonInVwr
    )
    
    static readonly PDO_PATIENT_SITE_DISPLAY_LATEST_SMS = new ProviderDepartmentOptionSort(
        'Display Latest SMS SAT',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteDisplayLatestSmsSuggestedArrivalTime
    )
    
    // static readonly PDO_PATIENT_SITE_USE_BETA_SITE = new ProviderDepartmentOptionSort(
    //     'Use Beta Patient Site',
    //     'pdos.nodes[0].'
    //         +ProviderDepartmentOptionItem.patientSiteUseBetaSite
    // )
    
    static readonly PDO_MIN_MINUTES_BETWEEN_REMINDER_TEXTS = new ProviderDepartmentOptionSort(
        'Min minutes between reminder texts (pre)',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.minMinutesBetweenReminderTexts
    )
    
    static readonly PDO_GOOGLE_PLACE_ID = new ProviderDepartmentOptionSort(
        'Google Place',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.googlePlaceId
    )
    
    static readonly PDO_PATIENT_SITE_SHOW_LOCATION = new ProviderDepartmentOptionSort(
        'Show Location',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteShowLocation
    )
    
    static readonly PDO_PATIENT_SITE_DIRECTIONS_TRANSLATION = new ProviderDepartmentOptionSort(
        'Directions t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteDirectionsTranslationId
    )
    
    static readonly PDO_PATIENT_SITE_REMOTE_DIRECTIONS_TRANSLATION = new ProviderDepartmentOptionSort(
        'Remote Directions t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteRemoteDirectionsTranslationId
    )
    
    static readonly PDO_PATIENT_SITE_VIDEO_LINK = new ProviderDepartmentOptionSort(
        'About Video Link t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteAboutVideoLinkTranslationId
    )
    
    static readonly PDO_PATIENT_SITE_DISPLAY_PAYMENT_PORTAL = new ProviderDepartmentOptionSort(
        'Display Payment Portal',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteDisplayPaymentPortal
    )
    
    static readonly PDO_PATIENT_SITE_DISPLAY_PATIENT_PORTAL = new ProviderDepartmentOptionSort(
        'Display Patient Portal',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteDisplayPatientPortal
    )
    
    static readonly PDO_PATIENT_SITE_FAQ_TRANSLATION = new ProviderDepartmentOptionSort(
        'FAQ t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteFaqTranslationId
    )
    
    static readonly PDO_PATIENT_SITE_INTAKE_FORM_TRANSLATION = new ProviderDepartmentOptionSort(
        'Intake Form t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteIntakeFormUrlTranslationId
    )
    
    static readonly PDO_PATIENT_SITE_SHOW_CALL_BUTTON = new ProviderDepartmentOptionSort(
        'Show Call Button',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteShowCallButton
    )
    
    static readonly PDO_PATIENT_SITE_CONTACT_INFO_TRANSLATION = new ProviderDepartmentOptionSort(
        'Contact Info t9n',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.patientSiteContactTranslationId
    )
    
    static readonly PDO_STATS_TYPE = new ProviderDepartmentOptionSort(
        'Appointment Delay Calc Stats Type',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.statsType
    )
    
    static readonly APPOINTMENTS_DISABLE_AUTO_TEXTING = new ProviderDepartmentOptionSort(
        'Disable Autotexting',
        'pdos.nodes[0].'
            +ProviderDepartmentOptionItem.appointmentsAutoTextingDisabled
    )

    public constructor(
        public readonly key: string,
        public readonly sortPath: string,
        public defaultSort: ListSortDirection = ListSortDirection.ASC,
        public flowFuncs?: ((any: any) => any)[]
    ) {}

    toString() {
        return this.key
    }
}

export type ProviderDepartmentOptionSortKey = keyof typeof ProviderDepartmentOptionSort