export enum AppointmentStatus {
    SCHEDULED = 'SCHEDULED',
    CHECKING_IN = 'CHECKING_IN',
    CHECKED_IN = 'CHECKED_IN',
    WAIT_FOR_INTAKE = 'WAIT_FOR_INTAKE',
    INTAKE = 'INTAKE',
    WAIT_FOR_DOCTOR = 'WAIT_FOR_DOCTOR',
    EXAM = 'EXAM',
    WAIT_FOR_CHECKOUT = 'WAIT_FOR_CHECKOUT',
    CHECKING_OUT = 'CHECKING_OUT',
    CHECKED_OUT = 'CHECKED_OUT',
    CANCELED = 'CANCELED',
    LEFT_WITHOUT_BEING_SEEN = 'LEFT_WITHOUT_BEING_SEEN',
    NO_SHOW = 'NO_SHOW',
    UNKNOWN = 'UNKNOWN',
}

export enum CalculatedAppointmentStatus {
    Scheduled = 'Scheduled',
    CheckingIn = 'CheckingIn',
    CheckedIn = 'CheckedIn',
    WaitForIntake = 'WaitForIntake',
    Intake = 'Intake',
    WaitForDoctor = 'WaitForDoctor',
    Exam = 'Exam',
    WaitForCheckout = 'WaitForCheckout',
    CheckingOut = 'CheckingOut',
    CheckedOut = 'CheckedOut',
    Canceled = 'Canceled',
    LeftWithoutBeingSeen = 'LeftWithoutBeingSeen',
    NoShow = 'NoShow',
    Rescheduled = 'Rescheduled',
    Arrived = 'Arrived',
    Done = 'Done',
    Unknown = 'Unknown'
}

export const CalculatedAppointmentStatusOrderOfImportance: CalculatedAppointmentStatus[] =
    [
        CalculatedAppointmentStatus.Scheduled,
        CalculatedAppointmentStatus.Arrived,
        CalculatedAppointmentStatus.CheckingIn,
        CalculatedAppointmentStatus.CheckedIn,
        CalculatedAppointmentStatus.WaitForIntake,
        CalculatedAppointmentStatus.Intake,
        CalculatedAppointmentStatus.WaitForDoctor,
        CalculatedAppointmentStatus.Exam,
        CalculatedAppointmentStatus.WaitForCheckout,
        CalculatedAppointmentStatus.CheckingOut,
        CalculatedAppointmentStatus.CheckedOut,
        CalculatedAppointmentStatus.Rescheduled,
        CalculatedAppointmentStatus.Canceled,
        CalculatedAppointmentStatus.LeftWithoutBeingSeen,
        CalculatedAppointmentStatus.NoShow,
        CalculatedAppointmentStatus.Unknown,
    ]

export const CompletedAppointmentStatuses = [AppointmentStatus.CHECKED_OUT]

export const NotSeenAppointmentStatuses = [
    AppointmentStatus.CANCELED,
    AppointmentStatus.LEFT_WITHOUT_BEING_SEEN,
    AppointmentStatus.NO_SHOW,
]

export const NotActiveAppointmentStatuses = [
    AppointmentStatus.WAIT_FOR_CHECKOUT,
    AppointmentStatus.CHECKING_OUT,
    AppointmentStatus.CHECKED_OUT,
    AppointmentStatus.CANCELED,
    AppointmentStatus.INTAKE,
    AppointmentStatus.LEFT_WITHOUT_BEING_SEEN,
    AppointmentStatus.NO_SHOW,
]

export const NotSeenCalculatedAppointmentStatuses = [
    CalculatedAppointmentStatus.Canceled,
    CalculatedAppointmentStatus.Rescheduled,
    CalculatedAppointmentStatus.LeftWithoutBeingSeen,
    CalculatedAppointmentStatus.NoShow,
    CalculatedAppointmentStatus.Unknown,
]

export const NotStartedAppointmentStatuses = [
    CalculatedAppointmentStatus.Scheduled,
    CalculatedAppointmentStatus.CheckingIn,
    CalculatedAppointmentStatus.WaitForIntake,
    CalculatedAppointmentStatus.Intake,
    CalculatedAppointmentStatus.WaitForDoctor,
]

export const DashboardViewStatuses = [
    AppointmentStatus.SCHEDULED, 
    AppointmentStatus.CHECKING_IN, 
    AppointmentStatus.CHECKED_IN, 
    AppointmentStatus.WAIT_FOR_INTAKE, 
    AppointmentStatus.INTAKE, 
    AppointmentStatus.WAIT_FOR_DOCTOR, 
    AppointmentStatus.EXAM, 
    AppointmentStatus.WAIT_FOR_CHECKOUT, 
    AppointmentStatus.CHECKING_OUT,  
]

export const NotStartedCalculatedAppointmentStatuses = [
    CalculatedAppointmentStatus.Scheduled,
    CalculatedAppointmentStatus.CheckingIn,
    CalculatedAppointmentStatus.WaitForIntake,
    CalculatedAppointmentStatus.Intake,
    CalculatedAppointmentStatus.WaitForDoctor,
]
